html {
    height: 100vh;
}

body {
    height: 100vh;   /* Dodajte ovu liniju */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-image: url('./background.svg');
    background-attachment: fixed;
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

nav {
    margin-bottom: 50px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

nav li {
    padding: 10px 20px;
    background-color: #0070f3;
    border-radius: 5px;
}

nav a {
    color: white;
    text-decoration: none;
}

nav a:hover {
    text-decoration: underline;
}

h2, h3 {
    color: #0070f3;
}

table {
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    border: 1px solid #eaeaea;
}

table th, table td {
    padding: 10px 15px;
    border: 1px solid #eaeaea;
}

table th {
    background-color: #f6f9fc;
    color: #0070f3;
}

table tr:hover {
    background-color: #f0f3f7;
}

button {
    padding: 10px 20px;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #005ecb;
}

/* Osnovni stilovi za aplikaciju */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.module {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px; /* Zaobljene ivice */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Dodaje sjenku za "plutajući" efekt */
    margin-bottom: 20px; /* Razmak između modula */
}

/* Ako želite da neke module imaju drugačije stilove, možete koristiti dodatne klase */
.module.blue {
    background-color: #e3f2fd;
}

.module.red {
    background-color: #ffebee;
}

/* Modal stilovi */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
}

.modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
}

.funds-container {
    display: flex;
    justify-content: center;  /* Ažurirano da bude centrirano */
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

.funds-item {
    display: flex;
    align-items: center;
}

.funds-item img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.funds-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%; /* možete prilagoditi ovu vrijednost prema vašem ukusu */
}

@media (max-width: 767px) {
    .funds-balance {
        width: 85%;
    }
}

.escrow-form {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
}

.escrow-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.escrow-input-group label,
.escrow-input-group select,
.escrow-input-group input {
    margin-right: 25px;
}

@media (max-width: 767px) {
    .escrow-input-group {
        flex-direction: column;
    }

    .escrow-input-group label,
    .escrow-input-group select,
    .escrow-input-group input {
        margin-right: 0;
        margin-bottom: 15px;
        width: 125%;
    }
}

.calculation-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #e5e5e5;
    border-radius: 5px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.escrow-input-group {
    flex: 1;
    padding: 0 10px;
}

.funds-form {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.funds-form label {
    flex: 1;
    margin: 0 10px 20px;
    display: flex;
    justify-content: space-between;
}

.funds-form input {
    flex: 0.5;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.funds-form button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.funds-form button:last-child {
    margin-right: 0;
}

.funds-form button:hover {
    background-color: #0056b3;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin-top: 200px;
}

.login-form {
  padding: 50px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form label {
  display: block;
  margin-bottom: 15px;
}

.login-form input {
  width: 93%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.login-form button:hover {
  background-color: #0056b3;
}

.transaction-table {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    width: 100%;
    max-width: 800px;
    border: 1px solid #eaeaea;
}

.transaction-table th, .transaction-table td {
    padding: 10px 15px;
    border: 1px solid #eaeaea;
    text-align: center;
}

.transaction-table th {
    background-color: #f6f9fc;
    color: #0070f3;
}

.transaction-table tr:hover {
    background-color: #f0f3f7;
}

@keyframes moveBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}

body {
    background-image: url('./background.svg');
    background-repeat: repeat-x;
    animation: moveBackground 30s linear infinite;
}
